.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

.info-text {
    text-align: center;
}

@media screen and (max-width: 550px) {
    .info-text {
        font-size: initial !important;
        text-align: center;
    }
}