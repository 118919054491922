.DerechoInfo {
    padding-left: 20%;
    padding-right: 20%;
}

.DerechoText {
    text-align: justify;
}

/*@media screen and (max-width: 479px) {*/
@media screen and (max-width: 840px) {
    .DerechoInfo {
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media screen and (max-width: 550px) {
    .DerechoText {
        font-size: initial !important;
    }
}