.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.title {
    color: #989898;
    margin-bottom: 0%;
    font-size: calc(50px + 2vmin);
    font-family: 'Montserrat', sans-serif;

}

.titleDate {
    font-weight: 500;
}

p {
    color: #626262;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
}

.timeLabel {
    color: #626262;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    margin-top: 6.5rem;
}

/* .jumbotron {
    background-color: transparent!important;
}*/

.App-header {
    /* background-color: #282c34; */
    background-color: whitesmoke;
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

button.btnForm.btn.btn-success {
    margin-right: 10px;
    background-color: #0084FB;
    border: 0;
    border-radius: 0;
    color: white;
    width: 120px;
    height: 45px;
}

button.btnForm.btn.btn-warning {
    margin-right: 10px;
    background-color: #FF5D00;
    border: 0;
    border-radius: 0;
    color: white;
    width: 120px;
    height: 45px;
}

.inputClave {
    border: 1px solid black !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    text-align: center;

}

.labelForm {
    color: #626262;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
}

.App-link {
    color: #61dafb;
}

.smallForm {
    font-size: 40%;
    color: gray;
}

.hidden {
    display: none;
}

/* mis añadidos: */

.btn-w-m {
    min-width: 120px;
}

/* btn-primary */
/*.btn-primary.btn-outline {
    color: #1ab394;
}*/

.btn-primary {
    background-color: #0084FB;
    border-color: #0084FB;
    color: #FFFFFF;
    border-radius: 0;

}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary.active:focus {
    background-color: #FF5D00;
    border-color: #FF5D00;
    color: #FFFFFF;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
    background-color: #989898;
    border-color: #989898;
    /*background-color: #1dc5a3;
    border-color: #1dc5a3;*/
}

/* btn-danger */

.btn-danger.btn-outline {
    color: #ed5565;
}

.btn-danger {
    background-color: #FF5D00;
    border-color: #FF5D00;
    color: #FFFFFF;
    border-radius: 0;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger.active:hover,
.btn-danger.active:focus {
    background-color: #0084FB;
    border-color: #0084FB;
    color: #FFFFFF;
}

.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
    background-image: none;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger.active[disabled],
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
    background-color: #ef6776;
    border-color: #ef6776;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}